<template>
	<div class="h-100 gradient-background fullscreen">
        <v-container class="h-70vh bg-homeBg">
            <v-row no-gutters align="center" justify="center" class="h-70vh mt-16">
                <v-col cols="12" sm="12" md="8" xl="8" class="ml-1">
                    <div class="d-flex flex-column">
                        <div class="home-64 marcellus-sc r-p-b-4">
                            Own Your Community's Success
                        </div>
                        <div class="home-24 lexend-deca my-10 r-m-y-0 r-p-b-4 text-primaryGrey gradient-underline-hover">
                            Bring your followers to Untrading and transform your influence into a sustainable, growing asset. It's time to truly own the value you create.
                        </div>
                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="12" v-if="!token">
                                <router-link :to="'/connectwallet?redirectUrl=' + currentUrl" class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white home-18 lexend-deca font-weight-bold r-m-t-8" width="200" height="48" aria-label="Connect Wallet">Connect Wallet</v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <Estimator class="position-absolute estimator"></Estimator>
        <v-container class="pa-0 r-p-x-0 pt-500">
            <v-row no-gutters class="pb-600">
                <v-col cols="12">
                    <v-row no-gutters justify="center" align="center">
                        <v-col cols="12" sm="12" md="8" xl="8" xxl="8" class="d-flex flex-column">
                            <div class="home-36 marcellus-sc">Are you tired of platform-dictated payouts?</div>
                            <div class="home-20 lexend-deca font-weight-bold mt-4">Untrading puts you in control - an economic platform for your influence</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" xl="8" xxl="8" class="d-flex flex-column fill-height mt-10">
                            <v-row no-gutters justify="start" class="r-m-t-16">
                                <v-col cols="12" sm="6" md="6" class="pr-16 r-p-l-0 p-p-l-8 m-t-16 px-0">
                                    <h4 class="h4 mb-4 text-white03 font-weight-bold">Your Community, Your Rules</h4>
                                    <div class="h5 text-primaryGrey lexend-deca">Using blockchain, you can set your own terms and reward structures. You are in control of your payout plan.</div>
                                    <h4 class="h4 mt-16 mb-4 text-white03 font-weight-bold">True Ownership - Your DAOs, Your Hedge Funds</h4>
                                    <div class="h5 text-primaryGrey lexend-deca">Unlike YouTube or X, where the payout rules are set by the platforms, you are in charge of your mini-DAOs and decentralized hedge pools.</div>
                                </v-col>
                                <v-col cols="11" sm="6" md="6" class="pl-16 r-p-l-0 p-p-l-8 m-t-16 px-0">
                                    <h4 class="h4 mb-4 text-white03 font-weight-bold">Lasting Rewards</h4>
                                    <div class="h5 text-primaryGrey lexend-deca">Benefit from Originators' Rewards and Future Rewards, ensuring you profit from your community's long-term success.</div>
                                    <h4 class="h4 mt-16 mb-4 text-white03 font-weight-bold">Grow Together</h4>
                                    <div class="h5 text-primaryGrey lexend-deca">Untrading provides a platform where you can manage your community's economics as it grows - automatically and transparently.</div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" xl="8" xxl="8" class="d-flex flex-column fill-height mt-100">
                            <div class="home-18 lexend-deca">Ready to lead your community into the future of decentralized finance?</div>
                            <a href="https://kingdoms.untrading.org" class="del-underline gradient-left-red-purple-200-dark mt-16" target="_blank">
                                <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white h5" width="200" height="48" aria-label="Learn More">Learn More</v-btn>
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Footer -->
            <v-row no-gutters justify="center" class="bg-homeBg">
                <v-col cols="12" sm="12" md="8" class="px-0">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider class="bg-homeBg"></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" class="gradient-underline-hover h5 d-flex justify-end align-center my-2">
                    <Theme show-text size="22"></Theme>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Estimator from './v2/components/Estimator.vue';
import Footer from './v2/components/Footer.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            currentUrl: window.location.href,
        }
    },
    components: { Footer, Theme, Estimator },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['token', 'responsive'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {

    }
}
</script>
<style scoped>

</style>